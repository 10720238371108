import { Box, Button, Typography } from "@mui/material";
import React from "react";

const Index = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: "100% !important",
        width: "100%",
      }}
    >
      <div>
        <Typography variant="h1" sx={{ fontWeight: 800 }}>
          404
        </Typography>

        <p>Uh-oh!</p>

        <p>We can't find that page.</p>

        <div>
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
              mt: 2,
            }}
            href="/"
          >
            Go Back to Home Page
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Index;
