import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
// import Education from "./education";
import Experience from "./experience";
import Intro from "./intro";
import Skills from "./skills";
import NavBar from "./navbar";
import styles from "./index.module.css";
import Footer from "./footer";
import Blog from "./blog";
import NotFound from "./notFound";
// import Certifications from "./certifications";
// import AboutMe from "./aboutMe";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDiOsEfDmkLmdsf9nIRGQS4MRtYILZKyjY",
  authDomain: "my-personal-website-5e5fc.firebaseapp.com",
  projectId: "my-personal-website-5e5fc",
  storageBucket: "my-personal-website-5e5fc.appspot.com",
  messagingSenderId: "397344642226",
  appId: "1:397344642226:web:ebbae487c67068146fa5ab",
  measurementId: "G-C9ELQH6GVM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

const theme = createTheme({
  palette: {
    mode: "dark",
    type: "dark",
    primary: {
      main: "#D9BBF9",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#1f1f1f",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    body1: {
      fontWeight: 450,
    },
  },
});

const HomePage = () => {
  return (
    <>
      <Intro />
      {/* <AboutMe /> */}
      {/* <MyWork /> */}
      <Skills />
      <Experience />
      {/* <Certifications /> */}
      {/* <Education /> */}
    </>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box
        className={styles.main_box}
        sx={{ flexGrow: 1, paddingLeft: "20%", paddingRight: "20%", mb: "10%" }}
      >
        <NavBar theme={theme} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default App;
