import { Box } from "@mui/material";
import React from "react";

const Blog = () => {
  return (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <div>Coming soon</div>
    </Box>
  );
};

export default Blog;
