import { Button, Box, Grid } from "@mui/material";
import styles from "./index.module.css";
import ResponsiveDrawer from "./mobileDrawer";

const NavBar = ({ theme }) => {
  const myBackgroundColor = theme?.palette.background.default;
  return (
    <div>
      <Grid container className={styles.navbar}>
        <Grid item display={"flex"}>
          <Button
            href="/"
            sx={{ textTransform: "capitalize", fontSize: "16px" }}
          >
            Home
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
            }}
            href={"https://topmate.io/vishnu_teja_kugarthi/"}
            target="_blank"
          >
            Book a Call
          </Button>
          {/* <Button
            href="/blog"
            sx={{ textTransform: "capitalize", fontSize: "16px" }}
          >
            Blog
          </Button> */}
        </Grid>
      </Grid>
      {/* MOBILE NAVBAR */}
      <Box
        position="fixed"
        top={"0px"}
        left={"0px"}
        // justifyContent="flex-start"
        // display={"flex"}
        width={"100%"}
        sx={{
          zIndex: "9999",
          backgroundColor: myBackgroundColor,
          display: { xs: "block", sm: "none" },
        }}
      >
        {/* <Box className="mobile_side_nav"> */}
        <ResponsiveDrawer my_resume={process.env.REACT_APP_RESUME_URL} />
      </Box>
    </div>
  );
};

export default NavBar;
