import { Box, Grid, Divider, IconButton } from "@mui/material";
import {
  LinkedIn,
  Instagram,
  // Copyright,
  // Call,
  Mail,
} from "@mui/icons-material";
import EmailClipboard from "../intro/emailClipboard";
// import styles from "./footer.module.css";

// Ref - https://codepen.io/baahubali92/pen/KbRBxJ

const Footer = () => {
  return (
    <Box
      sx={{
        // my: 10
        mt: 10,
      }}
    >
      <footer>
        <Divider variant="middle" />
        {/* social media links */}
        <Grid container my={3} display={"flex"} justifyContent={"center"}>
          <Grid
            item
            gap={1}
            // xs={6}
            // display={"flex"}
            // justifyContent={"flex-start"}
          >
            <IconButton
              aria-label="LinkedIn"
              href="https://www.linkedin.com/in/vishnu-teja-kugarthi"
              target={"_blank"}
            >
              <LinkedIn fontSize="large" color="primary" />
            </IconButton>

            <IconButton
              aria-label="instagram"
              href="https://www.instagram.com/i_vishnuteja/"
              target={"_blank"}
            >
              <Instagram fontSize="large" color="primary" />
            </IconButton>
            <EmailClipboard>
              <Mail fontSize="large" color="primary" />
            </EmailClipboard>
          </Grid>
        </Grid>
      </footer>
    </Box>
  );
};

export default Footer;
