import { Typography, Box, Stack } from "@mui/material";

import styles from "./index.module.css";

const Experience = () => {
  return (
    <div>
      <Box
        id="experience"
        sx={{
          // my: 5,
          pt: 10,
        }}
      >
        <Typography variant="h5" color="primary">
          Work Experience
        </Typography>
        <Box>
          <Box
            sx={{
              mt: 2,
            }}
          >
            Web Developer at IBM, Sep 2018 - July 2024
            <ol>
              {/* 1 */}
              <Stack direction="column" spacing={2}>
                <li>
                  <div className={styles.flex_container}>
                    <span className={styles.left_text}>
                      <strong>Revamping an Older Application</strong>
                    </span>
                    <span className={styles.right_text}>
                      Dec 2018 – Dec 2020
                    </span>
                  </div>
                  <ul>
                    <li>
                      Led the revamp of a legacy application, achieving 90%
                      positive user feedback.
                    </li>
                    <li>
                      Conducted user research and iteratively refined the design
                      to enhance the user experience.
                    </li>
                    <li>
                      Focused on delivering user-centric solutions using
                      iterative design strategies.
                    </li>
                    <li>Tech stack: React.js, Node.js, Carbon Design System</li>
                  </ul>
                </li>
                {/* 2 */}
                <li>
                  <div className={styles.flex_container}>
                    <span className={styles.left_text}>
                      <strong>Micro Frontend Navigation Bar</strong>
                    </span>
                    <span className={styles.right_text}>
                      Jan 2021 – Dec 2021
                    </span>
                  </div>
                  <ul>
                    <li>
                      Developed a global navigation bar that improved UI and
                      deployment speed for a web application.
                    </li>
                    <li>
                      Enhanced user experience while solving operational
                      challenges in an agile environment.
                    </li>
                    <li>Tech stack: React.js, Node.js, Material UI</li>
                  </ul>
                </li>
                {/* 3 */}
                <li>
                  <div className={styles.flex_container}>
                    <span className={styles.left_text}>
                      <strong>Knowledge-Sharing Platform</strong>
                    </span>
                    <span className={styles.right_text}>
                      Jan 2022 – Feb 2023
                    </span>
                  </div>
                  <ul>
                    <li>
                      Created a platform to facilitate knowledge sharing with
                      internal details and learning tutorials.
                    </li>
                    <li>
                      Conducted stakeholder interviews and design reviews to
                      ensure alignment with user needs.
                    </li>
                    <li>Tech stack: React.js, Node.js, Material UI</li>
                  </ul>
                </li>
                {/* 4 */}
                <li>
                  <div className={styles.flex_container}>
                    <span className={styles.left_text}>
                      <strong>Dashboard Web Application</strong>
                    </span>
                    <span className={styles.right_text}>
                      Mar 2023 – July 2024
                    </span>
                  </div>
                  <ul>
                    <li>
                      Developed a web application with customizable dashboards,
                      significantly improving business efficiency.
                    </li>
                    <li>
                      Implemented an admin panel for dashboard customization,
                      transitioning from static Excel files to dynamic,
                      real-time analytics.
                    </li>
                    <li>
                      Tech stack: React.js, Node.js, Chakra UI, Tailwind CSS
                    </li>
                  </ul>
                </li>
              </Stack>
            </ol>
          </Box>
          <Box
            sx={{
              mt: 5,
            }}
          >
            Web Developer at TCS, Aug 2024 - Present
            <ol>
              <Stack direction="column" spacing={2}>
                {/* 1 */}
                <li>
                  <div className={styles.flex_container}>
                    <span className={styles.left_text}>
                      <strong>Lloyds Banking Group</strong>
                    </span>
                    <span className={styles.right_text}>
                      Aug 2024 - Present
                    </span>
                  </div>
                  <ul>
                    <li>
                      Developed user interfaces and APIs for Lloyds Bank,
                      focusing on seamless user experiences and secure data
                      handling tailored to the banking sector.
                    </li>
                    <li>Tech stack: React.js, Node.js</li>
                  </ul>
                </li>
              </Stack>
            </ol>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Experience;
