import { Typography, Box, Stack } from "@mui/material";
// import styles from "./index.module.css";

// eslint-disable-next-line no-unused-vars
const itemData = [
  "Wireframing",
  "Prototyping",
  "User Experience (UX) and User Interface (UI) Design",
  "Front-End Development (HTML, JavaScript, CSS)",
  "React",
  "Sass",
  "Storybook",
  "Jest Testing Framework",
  "React Testing Library",
  "Mock Service Worker",
  "Web Components",
  "Micro Frontends",
  "Webpack",
];

const Skills = () => {
  return (
    <Box
      id="skills"
      sx={{
        width: "100%",
        // my: 10,
        pt: 10,
      }}
    >
      <Typography variant="h5" color="primary">
        Skills and Tools
      </Typography>

      <p>
        <Stack direction="column" spacing={1}>
          <p>
            <strong>Front-End Technologies:</strong> React.js, Redux, React
            Hooks, HTML, CSS, JavaScript, JSON <br />
          </p>
          <p>
            <strong>Back-End Technologies:</strong> Node.js <br />
          </p>
          <p>
            <strong>Testing Tools:</strong> React Testing Library, Jest, Mock
            Service Worker, Cypress <br />
          </p>
          <p>
            <strong>UI Frameworks and Libraries:</strong> Carbon Design,
            Material Design, Tailwind CSS, Chakra UI <br />
          </p>
          <p>
            <strong>DevOps and Cloud:</strong> CI/CD, Db2, IBM Cloud <br />
          </p>
          <p>
            <strong>Development Tools:</strong> Visual Studio Code, Jira,
            Trello, Slack, Notion <br />
          </p>
          <p>
            <strong>Design and Collaboration Tools:</strong> Figma, FigJam, MS
            Office, Google Workspace
          </p>
        </Stack>
      </p>
    </Box>
  );
};

export default Skills;
